import React from 'react'

// Libraries
import { Outlet } from 'react-router-dom'

const MainLayout = () => {
  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default MainLayout
