import React from "react";

// Component
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Wrapper from "../../Components/Wrapper/Wrapper";
import LoaderWrapper from "../../Components/Loader/LoaderWrapper";

// Data
import options from "../../Data/particales";

// Images
import LOGO from "../../Assets/images/logo.svg";

// Styles
import Style from "./HomeView.module.css";
import config from "../../Data/config";

const HomeView = () => {
  const particlesInit = async (main) => {
    // console.log(main)
    await loadFull(main);
  };
  return (
    <LoaderWrapper>
      <div className={Style.home}>
        <Wrapper>
          <div className={Style.wrapper}>
            <img src={LOGO} alt="" className={Style.logoImg} />
            <div className={Style.mainContent}>
              <h2 className={Style.mainHead}>
                <span>Finally Take Full</span> Control Of Your Blockchain
                Identity
              </h2>
              <p className={Style.subHead}>
                Unlock the Power of Secure, Decentralized Identity Management
                for a New Level of Freedom and Privacy with 2access
              </p>
              <a
                href={config.GET_STARTED}
                target="_blank"
                rel="noopener noreferrer"
                className={Style.buttonPt}
              >
                Let’s Get Started
              </a>
            </div>
          </div>
        </Wrapper>
      </div>
      <Particles id="tsparticles" init={particlesInit} options={options} />
    </LoaderWrapper>
  );
};

export default HomeView;
