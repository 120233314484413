const config = {
  WEBSITE_NAME: "2access",
};

if (process.env.NODE_ENV === "development") {
  config.GET_STARTED = "https://dev-bid-ft-pt.myblockchainid.io/";
} else {
  config.GET_STARTED = process.env.REACT_APP_GET_STARTED;
}

export default config;
